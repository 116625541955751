import React, { useState, useEffect } from 'react';

import useDeviceInfo from '../../hooks/useDeviceInfo';
import HeaderType from './header-type';
import SuppliesSearch from './supplies-search';
import HeaderContainer from './header-container';
import ProductRow from './product-row';
import SubscriptionInk from './subscription-ink';
import SubGroupHeader from './sub-group-header';
import { RenderEspotHtml } from './espot-manager';
import InstantInk from './instant-ink';
import useSuppliesSearch from '../useSuppliesSearch';

import withPageComponents from '../../page/components/with-page-components';
import { getGTMClickAttributes } from '../../metrics/metrics-helpers';

import './search-container.less';

export default withPageComponents(
    ({ suppliesSearch, defaultSearch, location }) => {
        const { query } = location || {};
        const searchTerm = decodeURI(query?.searchTerm || query?.keyword || '');
        const { productGroups } = suppliesSearch || {};
        if (!productGroups) return null;

        // TODO: ask for Intant ink group to have blank comlumn at the end for CTAs
        const productGroups2 = [
            { ...productGroups[0], columns: [...(productGroups[0]?.columns || []), { CTA: '' }] },
            ...productGroups?.slice(1),
        ];
        const { results } = useSuppliesSearch();
        const [showMore, setShowMore] = useState(false);
        const { innerWidth } = useDeviceInfo(['innerWidth']);

        useEffect(() => {
            setShowMore(false);
        }, [results]);

        const mobile = innerWidth < 768;
        const tablet = innerWidth < 1024;

        const supplies = results?.supplies?.sort((a, b) => a.rank - b.rank) || [];
        let subscriptionInk = null;

        const getInstankInk = (group, product) => {
            const show = group?.espot?.[0]?.show_espot;
            const espotName = group?.espot?.[1]?.espotName;
            const rules = group?.espot?.[2]?.espot_rule;

            if (show !== 'true' || !rules) return null;

            // const valid = product['etr_instantinkeligible'] === 'Yes' && !product['etr_subscription_kitid'];

            const valid = rules.every(rule => {
                const [key, value] = Object.entries(rule)?.[0];
                return (product[key] || '') === value || product?.[key]?.toLowerCase() === value?.toLowerCase();
            });

            if (valid) {
                const { instantInkPlan } = group;

                if (!instantInkPlan) return null;

                return <InstantInk {...instantInkPlan} eSpotName={espotName} />;
            }

            return null;
        };

        return (
            <>
                <SuppliesSearch
                    productGroups={productGroups2}
                    defaultSearch={defaultSearch || searchTerm}
                    autocompleteCount={parseInt(query?.aCount, 10)}
                />
                <HeaderContainer printers={results?.printers} banner={results?.banner} productGroups={productGroups} />
                {supplies?.map((group, index) => {
                    const groupKey = group.title.toLowerCase();
                    const labels = group.columns.map(col => Object.keys(col)[0]);

                    return (
                        <div
                            key={group.title}
                            className="searchContainer"
                            style={
                                mobile
                                    ? {}
                                    : {
                                          gridTemplateColumns: `182px auto repeat(${labels.length - 1}, 100px) 5px ${
                                              tablet ? '166px' : '300px'
                                          }`,
                                      }
                            }
                        >
                            {subscriptionInk && (
                                <SubscriptionInk
                                    labels={subscriptionInk.labels}
                                    group={subscriptionInk.group}
                                    mobile={mobile}
                                />
                            )}
                            <HeaderType
                                key={index}
                                title={group.title}
                                labels={labels}
                                printers={results?.printers}
                                group={group}
                            />
                            {group.groups.map((subgroup, index) => {
                                const { products } = subgroup;
                                const lastProduct = products?.[products.length - 1];
                                if (group.title?.toLowerCase() === 'paper') {
                                    const subset = subgroup.products.slice(0, 4);
                                    const rest = subgroup.products.slice(4);
                                    return (
                                        <>
                                            <SubGroupHeader group={group} subgroup={subgroup} mobile={mobile} />
                                            {subset.map((product, index) => {
                                                return (
                                                    <ProductRow
                                                        key={product.sku}
                                                        cols={group.columns}
                                                        product={product}
                                                        group={group}
                                                        subgroup={subgroup}
                                                        shippingMessage={group.shipMsg}
                                                        mobile={innerWidth < 768}
                                                        gridSpan={group.columns.length + 3}
                                                        multiCta={group?.CTA?.[0]?.noOfAddtoCartCTA}
                                                    />
                                                );
                                            })}
                                            {!showMore && rest.length > 0 && (
                                                <div
                                                    className="searchContainer-view-more"
                                                    style={{ gridColumn: `span ${mobile ? 2 : labels.length + 3}` }}
                                                    onClick={() => setShowMore(true)}
                                                    {...getGTMClickAttributes(
                                                        'linkClick',
                                                        'products',
                                                        `${group.titlePrinterOverride || group.title}-load-more`
                                                    )}
                                                >
                                                    View more
                                                </div>
                                            )}
                                            {showMore &&
                                                rest.map((product, index) => {
                                                    return (
                                                        <ProductRow
                                                            cols={group.columns}
                                                            product={product}
                                                            subgroup={subgroup}
                                                            group={group}
                                                            shippingMessage={group.shipMsg}
                                                            mobile={innerWidth < 768}
                                                            gridSpan={group.columns.length + 3}
                                                            multiCta={group?.CTA?.[0]?.noOfAddtoCartCTA}
                                                        />
                                                    );
                                                })}
                                        </>
                                    );
                                }

                                return (
                                    <>
                                        <SubGroupHeader group={group} subgroup={subgroup} mobile={mobile} />
                                        {subgroup.products.map((product, index) => {
                                            return (
                                                <>
                                                    <ProductRow
                                                        cols={group.columns}
                                                        product={product}
                                                        group={group}
                                                        subgroup={subgroup}
                                                        shippingMessage={group.shipMsg}
                                                        mobile={innerWidth < 768}
                                                        gridSpan={mobile ? 2 : group.columns.length + 3}
                                                        multiCta={group?.CTA?.[0]?.noOfAddtoCartCTA}
                                                    />
                                                    {groupKey === 'ink' && getInstankInk(group, product)}
                                                </>
                                            );
                                        })}
                                        {groupKey === 'subscription ink' &&
                                            group?.espot?.[0]?.show_espot === 'true' && (
                                                <RenderEspotHtml
                                                    eSpotName={group?.espot?.[1]?.espotName}
                                                    details
                                                    gtmActions={getGTMClickAttributes(
                                                        'linkClick',
                                                        'starter-kit',
                                                        `view-details`
                                                    )}
                                                />
                                            )}
                                    </>
                                );
                            })}
                        </div>
                    );
                })}
            </>
        );
    },
    { components: ['suppliesSearch'], location: true }
);
