import React from 'react';
import { DropWhite, Drop } from '@hpstellar/vis-icons';

import './ink-colors.less';

export default ({ colors, xl, center, isBundle = true }) => {
    if (!colors) return [];
    const colorList = Array.isArray(colors) ? colors : [colors];
    return (
        <div className="colorDrops">
            <div
                className="colorDrops-container"
                style={center ? { left: -Math.max((colorList.length * 16) / 2, 16) } : {}}
            >
                {colorList.map((color, index) => {
                    const xlOverride = color === '#222223' && isBundle;
                    return (
                        <div className="drop" style={{ left: index * -16 }}>
                            {xl || xlOverride ? (
                                <DropWhite style={{ fill: color }} />
                            ) : (
                                <Drop className="colorDrops-ink" style={{ fill: color }} />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
