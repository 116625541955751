import React from 'react';
import { Typography } from '@hpstellar/core';
import { Printers, InkCartridges } from '@hpstellar/icons';

import './result-row.less';

export default function ResultRow({ name, sku, imgUrl, type, click, small, large, display }) {
    const onSelect = productSku => {
        click(productSku);
    };

    return (
        <div
            className={`resultRowContainer${display ? ' resultRowContainer-display' : ' resultRowContainer-clickable'}`}
            onClick={() => onSelect(sku)}
        >
            <div
                className={`resultRowContainer-img${small ? ' resultRowContainer-img-small' : ''}${
                    large ? ' resultRowContainer-img-large' : ''
                }`}
            >
                {imgUrl && imgUrl !== 'null' && <img src={imgUrl} alt={name} />}
                {(!imgUrl || imgUrl === 'null') && (type === 'Supplies' ? <InkCartridges /> : <Printers />)}
            </div>
            <div className="resultRowContainer-title">
                <Typography className="resultRowContainer-name" variant="titleS">
                    {name}
                </Typography>
                <Typography variant="bodyS">{`PRODUCT # ${sku}`}</Typography>
            </div>
        </div>
    );
}
