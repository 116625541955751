import React from 'react';

import { Typography } from '@hpstellar/core';

import ResultRow from './result-row';
import HawkBanner from './hawk-banner';

import useSuppliesSearch from '../useSuppliesSearch';

import './header-container.less';

export default function HeaderContainer({ printers, banner, productGroups }) {
    const { search, clearResults, setLatestSearch, latestSearch } = useSuppliesSearch();

    if (!printers || printers?.length < 1) return null;

    const select = sku => {
        if (sku === latestSearch) return;

        clearResults();
        setLatestSearch(sku);
        search(sku, productGroups);
    };

    return (
        <div className="headerContainer">
            <div className="headerContainer-category">
                <Typography variant="bodyS">Printers</Typography>
            </div>
            {printers?.map((printer, index) => (
                <ResultRow
                    key={`${printer?.sku}-${index}`}
                    name={printer?.product_name || printer?.name}
                    sku={printer?.sku}
                    imgUrl={printer?.images?.[0]?.src}
                    click={select}
                    large
                    display={latestSearch === printer?.sku}
                />
            ))}
            {banner && <HawkBanner banner={banner} />}
        </div>
    );
}
