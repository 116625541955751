import React, { useMemo } from 'react';

import Link from '../../shared/components/hyperlink';

import { addGtmPropsToBanner } from '../../metrics/metrics-helpers';
import useDeviceInfo from '../../hooks/useDeviceInfo';

import './hawk-banner.less';

export default function HawkBanner({ banner }) {
    const { innerWidth, device } = useDeviceInfo(['device']);
    const isMobile = innerWidth < 768 || device === 'mobile';

    if (!banner || !banner?.ImageUrl) return null;

    const src = isMobile ? banner?.MobileImageUrl : banner?.ImageUrl;
    const alt = isMobile ? banner?.MobileAltTag : banner?.AltTag;

    const linkProps = useMemo(() => {
        const analyticsData = {};
        const { gtmActions } = addGtmPropsToBanner(
            { ...banner, title: banner?.Title || banner?.title },
            { device, id: 'hawksearch', metricConfig: { promotionClickType: 'cta' }, position: 1 },
            analyticsData
        );
        const actions = gtmActions?.get('ctaClick');

        return actions
            ? {
                  'data-gtm-category': actions.gtmCategory,
                  'data-gtm-id': actions.gtmId,
                  'data-gtm-value': actions.gtmValue,
              }
            : {};
    }, [banner]);

    return (
        <div className="hawkBanner-container">
            <Link className="link" to={banner?.ForwardUrl} {...linkProps}>
                <img src={src} alt={alt} />
            </Link>
        </div>
    );
}
