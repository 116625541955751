import React from 'react';

import ResultRow from './result-row';
import useDeviceInfo from '../../hooks/useDeviceInfo';
import { getGTMClickAttributes } from '../../metrics/metrics-helpers';
import { Helpers } from '../../core/src/helpers';

import './autocomplete-container.less';

export default function AutocompleteContainer({ results, onAutocompleteSelect, searchTerm }) {
    const { innerWidth } = useDeviceInfo();

    if (!results || results.length < 1) return null;

    return (
        <div>
            {results?.products?.map((product, index) => (
                <div
                    id={`${product?.Sku} ${index}`}
                    {...getGTMClickAttributes('searchAutoClick', searchTerm, product?.Sku, 'supplies-finder')}
                >
                    <ResultRow
                        name={product?.ProductName}
                        sku={product?.Sku}
                        imgUrl={Helpers.getCDNUrl(product?.Results?.Document?.productimage?.[0] || product?.Thumb?.Url)}
                        type={product?.Results?.Document?.product_type?.[0]}
                        click={onAutocompleteSelect}
                        small={innerWidth < 768}
                        searchTerm={searchTerm}
                    />
                </div>
            ))}
        </div>
    );
}
