/* eslint-disable react/no-danger */
import React, { useState } from 'react';

import { Button, Dropdown, Typography } from '@hpstellar/core';

import { RenderEspotHtml } from './espot-manager';
import { Helpers } from '../../core/src/helpers';
import { getGTMClickAttributes } from '../../metrics/metrics-helpers';

import './instant-ink.less';

export default function InstantInk({ logo, logoPNG, message, options, ctaText, ctaLink, eSpotName }) {
    const [ink, setInk] = useState(null);

    return (
        <div className="instantInk">
            <div className="instantInk-Logo">
                <img src={logoPNG} alt="instant ink" />
                <Typography variant="boldS">
                    <span dangerouslySetInnerHTML={Helpers.createMarkup(message)} />
                </Typography>
            </div>

            <Dropdown
                className="instantInk-dropdown"
                customLabel="Ink Plans"
                options={options.map(option => ({ label: option.label, value: option.value }))}
                onChange={val => setInk(val)}
            />

            <div>
                <Typography className="instantInk-price" variant="boldS">
                    {ink ? `$${ink}/mo.` : `from $${options?.[0]?.value}/mo.`}
                </Typography>
            </div>
            <div className="instantInk-cta">
                <Button to={ctaLink} color="dark" size="small">
                    {ctaText}
                </Button>
            </div>

            <RenderEspotHtml
                eSpotName={eSpotName}
                details
                gtmActions={getGTMClickAttributes('linkClick', 'instant-ink', `view-details`)}
            />
        </div>
    );
}
