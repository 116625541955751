import React, { useCallback } from 'react';

import { Helpers } from '../core/src/helpers';
import { transformProduct } from '../search/util';
import useSiteConfig from '../hooks/useSiteConfig';

/**
 *
 * @returns {function} - A function that takes in an array Hawksearch results and returns an array of transformed products
 */
export const useTransformProducts = () => {
    const siteConfig = useSiteConfig();
    const { basename } = siteConfig || {};
    const fn = useCallback(
        results => {
            return results.map(doc =>
                transformProduct(doc.Document, basename, { includeRest: true, optimizeImage: true, pickImage: true })
            );
        },
        [basename]
    );
    return fn;
};

const inGroup = (product, group = '') => {
    const { prodsubgrp, pm_producttype, product_type } = product;

    // Special case for Multi-packs
    if (prodsubgrp === 'Multi-packs' && group?.['mapping-rule']) {
        const subGroup = group['mapping-rule']?.[0]?.['filter_subgrp'];
        return subGroup === product?.filter_subgrp;
    }

    // Special case for Accessories
    if (!group.prodsubgrp && group.pm_producttype) {
        // check if value is defined and check if it matches the group
        if (pm_producttype) return pm_producttype.includes(group.pm_producttype);
        // pm_producttype on accessory return false
        return false;
    }

    const groups = Array.isArray(prodsubgrp) ? prodsubgrp : [prodsubgrp];

    //loops through prodsubgrp and returns true is any of the prodsubgrp matches the group
    return groups.reduce((acc, subGroup = '') => {
        return subGroup.toLocaleLowerCase().trim() === (group.prodsubgrp || '').toLocaleLowerCase().trim() || acc;
    }, false);
};

export const mapResults = Helpers.memoize(
    (searhTerm, productGroups, results, testGroup = false) => {
        let skus = [...results];

        const mapping = [
            ...productGroups,
            ...((testGroup && [
                {
                    ProductSubGroup: [{ prodsubgrp: 'ALL', desc: 'ALL', violator: 'Message' }],
                    columns: [{ ALL_DATA: '' }],
                },
            ]) ||
                []),
        ].reduce((gacc, group) => {
            const { ProductSubGroup = [], title, columns, ...rest } = group;
            const groups = ProductSubGroup.reduce((acc, subGroup) => {
                const products = results.filter(product => {
                    return inGroup(product, subGroup);
                });

                products.forEach(product => {
                    skus = skus.filter(sku => sku.sku !== product.sku);
                });

                if (products.length > 0) {
                    acc.push({ ...subGroup, products });
                }
                return acc;
            }, []);

            if (groups.length > 0) {
                gacc.push({
                    title,
                    columns,
                    groups,
                    ...rest,
                });
            }

            return gacc;
        }, []);

        return mapping;
    },
    (searhTerm, productGroups, results = []) => {
        return `${searhTerm}-${!!productGroups}-${results.length}`;
    }
);
