import React from 'react';
import { Typography } from '@hpstellar/core';
import { ShippingChecked } from '@hpstellar/icons';
import useDeviceInfo from '../../hooks/useDeviceInfo';
import withPageComponents from '../../page/components/with-page-components';
import { getIconWithSVG } from '../../category-landing-page/components/shared';
import { Helpers } from '../../core/src/helpers';

import './title-container.less';

export default withPageComponents(
    ({ disableIcon, suppliesSearch }) => {
        const { device } = useDeviceInfo(['device']);
        const { title, titleMobile, subtitle, subtitleMobile, icon } = suppliesSearch || {};
        const displayTitle = device === 'mobile' && titleMobile ? titleMobile : title;
        const displaySubtitle = device === 'mobile' && subtitleMobile ? subtitleMobile : subtitle;
        const displayShippingIcon = suppliesSearch && !disableIcon && !icon && /free shipping/gi.test(displaySubtitle);

        return (
            <div className="suppliesFinderTitle">
                {displayTitle && (
                    <Typography
                        className="suppliesFinderTitle-title"
                        tag="h1"
                        dangerouslySetInnerHTML={Helpers.createMarkup(displayTitle)}
                    />
                )}
                <div className="suppliesFinderTitle-subtitle-container">
                    {displayShippingIcon && <ShippingChecked aria-label="Shipping" />}
                    {!displayShippingIcon && icon && <span className="custom-icon">{getIconWithSVG({ icon })}</span>}
                    {displaySubtitle && (
                        <Typography
                            tag="p"
                            className="suppliesFinderTitle-subtitle fontLight"
                            dangerouslySetInnerHTML={Helpers.createMarkup(displaySubtitle)}
                        />
                    )}
                </div>
            </div>
        );
    },
    { components: ['suppliesSearch'] }
);
