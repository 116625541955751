import React from 'react';
import loadable from '@loadable/component';

import TitleContainer from './components/title-container';
import SearchContainer from './components/search-container';
import EspotManager from './components/espot-manager';
import useSiteConfig from '../hooks/useSiteConfig';
import { Helpers } from '../core/src/helpers';

import './supplies-finder.less';

const ElicitSearch = loadable(() => Helpers.retryFunc(() => import('../elicit')));

function SuppliesFinder({ disableIcon, defaultSearch }) {
    return (
        <div className="suppliesFinder">
            <div className="suppliesFinder-container">
                <TitleContainer disableIcon={disableIcon} />
                <SearchContainer defaultSearch={defaultSearch} />
                <EspotManager />
            </div>
        </div>
    );
}

export default props => {
    // TODO: Temporary container to conditionally render the SuppliesFinder component based on feature flag
    const { enableSuppliesFinder } = useSiteConfig();
    if (enableSuppliesFinder) {
        return <SuppliesFinder {...props} />;
    }
    return <ElicitSearch {...props} />;
};
