import React from 'react';

import './sub-group-header.less';

export default function SubGroupHeader({ group, subgroup, mobile }) {
    const { violator: popularity, desc: popularitySpecsDesc, prodsubgrp, prodsubgrpLabel } = subgroup;
    const popularitySpecs = prodsubgrpLabel || prodsubgrp;

    return (
        <div
            className="suppliesSubGroup suppliesSubGroup-banner"
            style={{ gridColumn: `span ${mobile ? 2 : group.columns.length + 3}` }}
        >
            {popularity && (
                <>
                    <div className="suppliesSubGroup-popularity">
                        <span>{popularity}</span>
                        <div className="suppliesSubGroup-chevron" />
                    </div>
                </>
            )}
            <div className="suppliesSubGroup-specs" style={popularity ? {} : { paddingLeft: 8 }}>
                <span className="suppliesSubGroup-text">{popularitySpecs}</span>
                <span className="suppliesSubGroup-desc">{popularitySpecsDesc}</span>
            </div>
        </div>
    );
}
