import { useDispatch, useSelector } from 'react-redux';

import { setSearchResults, reset, SUPPLIES_SEARCH_LOADING, SUPPLIES_SEARCH_KEYWORD } from './suppliesSearchActions';
import { mapResults, useTransformProducts } from './util';
import useSearchEndpoint from '../search/useSearchEndpoint';

export default function useSuppliesSearch() {
    const dispatch = useDispatch();
    const transformProducts = useTransformProducts();
    const [searchApi] = useSearchEndpoint('suppliesfinder');

    const latestSearch = useSelector(state => state.suppliesSearch.latestSearch);
    const setLatestSearch = latestSearch => {
        dispatch({ type: SUPPLIES_SEARCH_KEYWORD, latestSearch });
    };
    const isLoading = useSelector(state => state.suppliesSearch.loading);
    const setLoading = loading => {
        dispatch({ type: SUPPLIES_SEARCH_LOADING, loading });
    };

    const search = (query, productGroups) => {
        setLoading(true);
        try {
            searchApi.search({ Keyword: query }).then(({ data }) => {
                setLoading(false);

                const transformedResults = transformProducts(data?.Results);
                const printers = transformedResults?.filter(p => p.product_type === 'Printer');

                const supplies = mapResults(query, productGroups, transformedResults || []) || [];

                const results = { key: query, printers, supplies, banner: data?.Merchandising?.Items?.[0] };
                return dispatch(setSearchResults({ results, keyword: data?.Keyword }));
            });
        } catch (e) {
            setLoading(false);
        }
    };

    const clearResults = () => {
        dispatch(reset());
    };

    const results = useSelector(state => state.suppliesSearch.results);

    return {
        search,
        results,
        clearResults,
        isLoading,
        setLoading,
        latestSearch,
        setLatestSearch,
    };
}
