import React from 'react';
import { Button } from '@hpstellar/core/visId';
import { formatGtmAttrs } from '@hpstellar/utils';

import useAddToCart from '../../hooks/useAddToCart';
import { getPurchaseButtonProps } from '../../product/get-product-props';
import { MAX_QUANTITY } from '../../utility/helpers';

export default ({
    product,
    price,
    cartItem,
    quantity,
    className,
    children,
    cartId,
    position,
    onClick,
    ...btnProps
}) => {
    const { qty, lQty = MAX_QUANTITY } = cartItem || {};
    const { sku } = product || {};
    const addToCartHook = useAddToCart(true);
    const { purchaseBtnProps } = getPurchaseButtonProps(
        product,
        price,
        { addToCart: addToCartHook },
        { list: 'supplies-finder', cartId, position },
        undefined,
        {
            quantity,
            toUpperCase: true,
            oldGtmId: `supplies-finder-${sku}-${position}`
        }
    );
    //dont render button till price details are available
    if (!price || !purchaseBtnProps?.children) {
        return null;
    }
    return (
        <Button
            className={className}
            disabled={qty + quantity > lQty}
            {...purchaseBtnProps}
            {...formatGtmAttrs(purchaseBtnProps.gtmAction)}
            children={typeof children === 'function' ? children({ purchaseBtnProps }) : purchaseBtnProps.children}
            {...btnProps}
            onClick={e => {
                onClick && onClick();
                purchaseBtnProps.onClick(e);
            }}
        />
    );
};
