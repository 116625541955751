import React, { useState, useCallback } from 'react';
import './product-row.less';
import { PriceBlock, Typography, Link } from '@hpstellar/core';
import { InkCartridges } from '@hpstellar/icons';

import { Button, QuantitySelector } from '@hpstellar/core/visId';

import { getGTMClickAttributes } from '../../metrics/metrics-helpers';
import useDeviceInfo from '../../hooks/useDeviceInfo';
import useProductPrice from '../../hooks/useProductPrice';
import useCartState from '../../hooks/useCartState';
import { MAX_QUANTITY } from '../../utility/helpers';
import InkColors from './ink-colors';
import ProductCTA from './product-cta';

const QuantityMessage = ({ quantity, cartItem }) => {
    const { lQty = MAX_QUANTITY } = cartItem || {};
    const itemQTY = cartItem?.qty || 0;
    if (cartItem === 0 && quantity < lQty) {
        return null;
    }

    return (
        <div className="suppliesRow-quantity-message">
            {(quantity === lQty || itemQTY > 0) && `${itemQTY} in cart.`}
            {quantity + itemQTY === lQty && itemQTY !== 0 && ` ${lQty} maximum.`}
            {(quantity === lQty || itemQTY === lQty) && ` ${lQty} maximum.`}
        </div>
    );
};

/**
 * @param {Object} props
 * @param {Object} props.product    Product object
 * @param {Object} props.price    Price object
 * @param {Object} props.productYield    ProductYield object
 * @param {Object} props.violator   Violator message
 * @param {Object} props.shipMessage    Shipping message
 * @param {Array} props.content Array of content components
 * @param {Array} props.specs Specification Object
 *
 */

export default ({ product, cols, group, subgroup, shippingMessage, gridSpan, multiCta }) => {
    const { prices } = useProductPrice([product]);
    const { getCartItem, cartId } = useCartState();
    const { innerWidth } = useDeviceInfo();
    const cartItem = getCartItem(product.sku);
    const price = prices[product.sku];
    const [quantity, setQuantity] = useState(1);
    if (!product) {
        return null;
    }
    const { images, name, cartridge_replacement_note: notification } = product;
    const { src: image } = images?.[0] || {};
    const isReplacementService = group?.title?.toLowerCase()?.trim() === 'ink replacement service';
    let xlDrop =
        subgroup?.prodsubgrp?.toLowerCase()?.includes('high yield') ||
        subgroup?.prodsubgrp?.toLowerCase()?.includes('high capacity');
    if (subgroup?.prodsubgrp?.toLowerCase() === 'multi-packs') {
        xlDrop =
            product?.filter_subgrp?.toLowerCase()?.includes('high yield') ||
            product?.filter_subgrp?.toLowerCase()?.includes('high capacity');
    }

    const fieldProcessing = useCallback((product, field) => {
        if (field === 'paperfilmpacknum' && product[field]) {
            const sheets = product[field];
            return sheets?.includes('sheets') ? sheets : `${sheets} sheets`;
        }

        return product[field];
    }, []);

    const getBullets = useCallback(
        val => {
            if (!val) return null;

            const attrib = val.split(',').filter(v => product[v])[0];
            const text = product[attrib];

            if (!text) return null;

            return text;

            // TODO: come back to this ones PRISM data has been cleaned and more predictable
            // let bullets = [];
            // if (Array.isArray(text)) {
            //     bullets = text;
            // } else if (!text.includes('~')) {
            //     bullets = [text];
            // } else {
            //     bullets = text.split('~').map(bullet => `${bullet}`.replace(/,/g, ''));

            //     if (bullets.length > 1 && bullets[0].toLowerCase().trim() !== 'per cartridge:') {
            //         bullets = bullets.splice(1).map(b => `~${b}`);
            //     }
            // }

            // return bullets.map((bullet, index) => (
            //     <span key={index}>
            //         {bullet}
            //         <br />
            //     </span>
            // ));
        },
        [product],
    );

    return (
        <div className="suppliesRow">
            <div className="suppliesRow-content">
                <div className="suppliesRow-col suppliesRow-image">
                    <div className="suppliesRow-image-box">
                        {image && image !== 'null' && <img src={image} alt={name} />}
                        {(!image || image === 'null') && <InkCartridges />}
                    </div>
                </div>
                <div className="suppliesRow-col suppliesRow-desc">
                    {/* // TODO: need to come back to this once we have source data */}
                    {notification && (
                        <Typography className="suppliesRow-notification" responsive tag="h3" variant="boldS">
                            {notification}
                        </Typography>
                    )}
                    <Link
                        className="suppliesRow-title"
                        color="dark"
                        size="small"
                        variation="body"
                        weight="light"
                        to={product['productUrl']}
                    >
                        {name}
                    </Link>
                    {shippingMessage && group.title.trim() !== 'INK REPLACEMENT SERVICE' && (
                        <Typography className="suppliesRow-shipping-offers" tag="p" variant="boldS">
                            {shippingMessage}
                        </Typography>
                    )}
                </div>
                {cols.map((col, index) => {
                    const key = Object.keys(col)[0];
                    const value = col[key];
                    const columnKey = key.toLowerCase();
                    const position = index + 1;
                    if (columnKey === 'cta') {
                        return (
                            <div
                                className="suppliesRow-col suppliesRow-subInk-row suppliesRow-cta-button-fix"
                                key={index}
                            >
                                <div className="suppliesRow-subInk-col">
                                    <ProductCTA
                                        variation="primary"
                                        product={product}
                                        cartItem={cartItem}
                                        cartId={cartId}
                                        quantity={quantity}
                                        price={price}
                                        position={position}
                                        children={({ purchaseBtnProps }) =>
                                            cartItem?.qty > 0 && !purchaseBtnProps.disabled
                                                ? 'ADD MORE'
                                                : purchaseBtnProps.children
                                        }
                                    />
                                    <Button
                                        to={product['productUrl']}
                                        variation="secondary"
                                        {...getGTMClickAttributes(
                                            'linkClick',
                                            'starter-kit',
                                            `${product.sku}-learn-more`,
                                        )}
                                    >
                                        LEARN MORE
                                    </Button>
                                </div>

                                <QuantityMessage cartItem={cartItem} quantity={quantity} />
                            </div>
                        );
                    }

                    if (columnKey === 'benefit') {
                        return (
                            <div className="suppliesRow-col suppliesRow-default" key={index}>
                                <span className="suppliesRow-label">
                                    {`${key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}:`}
                                </span>
                                <ul>
                                    <li>
                                        <Typography tag="p" variant="bodyS">
                                            {product['feature_01_headline_01_statement']}
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography tag="p" variant="bodyS">
                                            {product['feature_01_headline_02_statement']}
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography tag="p" variant="bodyS">
                                            {product['feature_01_headline_03_statement']}
                                        </Typography>
                                    </li>
                                </ul>
                            </div>
                        );
                    }

                    if (columnKey === 'page yield' || columnKey === 'yields' || columnKey === 'yield') {
                        return (
                            <div className="suppliesRow-col suppliesRow-default" key={index}>
                                <span className="suppliesRow-label">{'Yield:'}</span>
                                <Typography className="suppliesRow-pageyield-label" tag="p" variant="bodyS">
                                    {getBullets(value)}
                                </Typography>
                            </div>
                        );
                    }

                    if (columnKey === 'color' || columnKey === 'colour') {
                        const regex = /XL.*Standard_Color/;
                        const isXLKit = regex.test(product?.sku);

                        return (
                            <div className="suppliesRow-col suppliesRow-color">
                                <span className="suppliesRow-label">Color:</span>
                                <InkColors
                                    colors={product[value]}
                                    xl={xlDrop}
                                    center={innerWidth >= 768}
                                    isBundle={isXLKit}
                                />
                            </div>
                        );
                    }
                    if (columnKey === 'price') {
                        return (
                            <div className="suppliesRow-col suppliesRow-price product-price">
                                <span className="suppliesRow-label">Price:</span>
                                <PriceBlock
                                    className="suppliesRow-price-block"
                                    mainPriceClassName="suppliesRow-price-main"
                                    style="slimTwoLinesRight"
                                    size="small"
                                    priceDifferenceVariant="amount"
                                    regularPrice={price?.regularPrice}
                                    salePrice={price?.salePrice}
                                />
                                {isReplacementService && (
                                    <Typography className="suppliesRow-qty-limit" responsive tag="p" variant="bodyS">
                                        (limit 1)
                                    </Typography>
                                )}
                            </div>
                        );
                    }
                    if (columnKey === 'quantity' || columnKey === 'qty') {
                        const { qty, lQty = MAX_QUANTITY } = cartItem || {};
                        return (
                            <div className="suppliesRow-col suppliesRow-actions suppliesRow-cta-button-fix">
                                {!multiCta && (
                                    <div
                                        className={`suppliesRow-quantity-control${
                                            isReplacementService ? ' suppliesRow-ink-replacement' : ''
                                        } ${price?.isOOS}`}
                                    >
                                        <QuantitySelector
                                            size="small"
                                            withInput="true"
                                            disabled={price?.isOOS || isReplacementService || qty + quantity > lQty}
                                            min={1}
                                            value={quantity}
                                            onChange={v => v <= MAX_QUANTITY - (cartItem?.qty || 0) && setQuantity(v)}
                                        />
                                    </div>
                                )}
                                <div className="buttons suppliesRow-cta-container">
                                    {multiCta ? (
                                        <div className="suppliesRow-multi-cta">
                                            {Array(parseInt(multiCta, 10))
                                                .fill(1)
                                                .map((_, i) => {
                                                    return (
                                                        <ProductCTA
                                                            product={product}
                                                            cartItem={cartItem}
                                                            cartId={cartId}
                                                            quantity={i + 1}
                                                            price={price}
                                                            position={position}
                                                            children={({ purchaseBtnProps }) => {
                                                                if (purchaseBtnProps.disabled) {
                                                                    return purchaseBtnProps.children;
                                                                }

                                                                return cartItem?.qty > 0 && !purchaseBtnProps.disabled
                                                                    ? `ADD ${i + 1} MORE`
                                                                    : `ADD ${i + 1} TO CART`;
                                                            }}
                                                        />
                                                    );
                                                })}
                                        </div>
                                    ) : (
                                        <ProductCTA
                                            className="suppliesRow-cta suppliesRow-cta-button"
                                            product={product}
                                            cartId={cartId}
                                            cartItem={cartItem}
                                            quantity={quantity}
                                            price={price}
                                            position={position}
                                            children={({ purchaseBtnProps }) =>
                                                cartItem?.qty > 0 && !purchaseBtnProps.disabled
                                                    ? 'ADD MORE'
                                                    : purchaseBtnProps.children
                                            }
                                            onClick={() => setQuantity(1)}
                                        />
                                    )}
                                    <QuantityMessage cartItem={cartItem} quantity={quantity} />
                                </div>
                            </div>
                        );
                    }

                    return (
                        <div className="suppliesRow-col suppliesRow-default" key={index}>
                            <span className="suppliesRow-label">
                                {key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}
                            </span>
                            <Typography className="suppliesRow-default-value" tag="p" variant="bodyS">
                                {fieldProcessing(product, value)}
                            </Typography>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
